.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

/* Navigation css */
.navbar {
  border-bottom: solid 1px #EBEBEB;
}
.bottom-tab-nav {
  border-top: solid 1px #EBEBEB;
  background-color: #F9F7FC;
}
.nav-link,
.bottom-nav-link {
  color: #55575b;
}
.bottom-nav-link.active {
  color: #922c88;
}
.bottom-tab-label {
  font-size: 12px;
}
/* ends */
